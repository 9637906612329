import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

interface Props {
  color: string
  title: string
  sub: string
  children: React.ReactNode
}

const SponsorItem: React.FC<Props> = ({ color, title, sub, children }) => {
  return (
    <Stack
      sx={{
        width: 1,
        minHeight: 150,
        background: `linear-gradient(90deg, ${color} 0%, rgb(241, 241, 241) 100%)`,
      }}
    >
      <Container>
        <Stack sx={{ my: 4 }}>
          <Typography
            fontWeight={800}
            sx={{ color: 'rgba(0,0,0,0.1)', fontSize: 60 }}
          >
            {sub}
          </Typography>
          <Typography variant="h5" fontWeight={700} sx={{ mt: '-60px' }}>
            {title}
          </Typography>
        </Stack>
        <Stack
          spacing={2}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ my: 2 }}
        >
          {children}
        </Stack>
      </Container>
    </Stack>
  )
}

export default SponsorItem
