import { getStrapiImage } from '@/lib/api'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import React from 'react'
import { getStrapiImageHeight, getStrapiImageWidth } from '../../lib/api'
import { SponsorItem } from './components'

const Sponsors: React.FC<Props<Sponsors>> = ({ data }) => {
  return (
    <>
      <Container sx={{ py: 4 }}>
        <Stack alignItems="center">
          <Typography
            variant="h1"
            sx={{ width: 1 }}
            textAlign="center"
            noWrap
            fontWeight={800}
            color="accent.main"
          >
            {data?.sub}
          </Typography>
          <Typography variant="h4" fontWeight={700} sx={{ mt: '-70px' }}>
            {data?.titre}
          </Typography>
        </Stack>
        <Typography
          color="text.secondary"
          textAlign="center"
          sx={{ width: { xs: 1, md: 0.7 }, mx: 'auto', mt: 4 }}
        >
          {data?.description}
        </Typography>

        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={3}
          sx={{ my: 6 }}
        >
          <Stack sx={{ position: 'relative', width: 150, height: 150 }}>
            <Image
              alt="Updev Challenge"
              src={getStrapiImage({
                data: data?.organisateurs?.data[0],
              } as unknown as Media)}
              // blurDataURL={getStrapiImagePlaceholder({
              //   data: data?.organisateurs?.data[0],
              // } as unknown as Media)}
              // placeholder="blur"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              priority
            />
          </Stack>
          <Stack sx={{ width: 2, height: 150, bgcolor: 'divider' }}></Stack>
          <Stack sx={{ position: 'relative', width: 120, height: 120 }}>
            <Image
              alt="Updev Challenge"
              src={getStrapiImage({
                data: data?.organisateurs?.data[1],
              } as unknown as Media)}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              // placeholder="blur"
              // blurDataURL={getStrapiImagePlaceholder({
              //   data: data?.organisateurs?.data[1],
              // } as unknown as Media)}
              priority
            />
          </Stack>
        </Stack>
      </Container>
      <Stack>
        {data?.liste.map((el, i) => (
          <SponsorItem
            key={i}
            title={el?.type}
            sub={el?.sub}
            color={el?.couleur}
          >
            {el?.medias?.data?.map((image) => (
              <Stack key={image?.data?.id}>
                <Image
                  src={getStrapiImage({ data: image } as unknown as Media)}
                  // blurDataURL={getStrapiImagePlaceholder({
                  //   data: image,
                  // } as unknown as Media)}
                  // placeholder="blur"
                  alt="updev"
                  width={getStrapiImageWidth({
                    data: image,
                  } as unknown as Media)}
                  height={getStrapiImageHeight({
                    data: image,
                  } as unknown as Media)}
                />
              </Stack>
            ))}
          </SponsorItem>
        ))}
      </Stack>
    </>
  )
}

export default Sponsors
